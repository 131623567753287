
import { defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmEmptyState from '@/components/shared/states/TmEmptyState.vue'
import WorkspaceUsersFaq from '@/components/pages/admin/workspace/users/WorkspaceUsersFaq.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    WorkspaceUsersFaq,
    TmButton,
    TmEmptyState,
    PageContent,
  },
  setup() {
    const { openModal } = useModals()

    return {
      openModal,
    }
  },
})
